import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
			TERRAxSPIKE
			</title>
			<meta name={"description"} content={"Оживіть свій автомобіль - відчуйте ідеальний блиск в автосервісі TERRAxSPIKE"} />
			<meta property={"og:title"} content={"TERRAxSPIKE"} />
			<meta property={"og:description"} content={"Оживіть свій автомобіль - відчуйте ідеальний блиск в автосервісі TERRAxSPIKE"} />
			<meta property={"og:image"} content={"https://terraxspike.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://terraxspike.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://terraxspike.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://terraxspike.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://terraxspike.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://terraxspike.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://terraxspike.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 190px 0" quarkly-title="Contacts-4">
			<Override slot="SectionContent" flex-direction="column" grid-template-columns="repeat(2, 1fr)" grid-gap="56px 54px" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="0px 0px 25px 0px"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0 0px 0px"
					margin="0px"
					md-width="auto"
				>
					<Text
						lg-padding="0px 0px 0px 0px"
						sm-font="normal 700 36px/1.2 --fontFamily-sans"
						margin="0px 0px 18px 0px"
						font="normal 600 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						text-align="left"
						padding="0px 0 0px 0px"
						lg-width="100%"
						lg-margin="0px 0px 25px 0px"
					>
						Зв'яжіться з нами
					</Text>
					<Text
						font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
						lg-margin="0px 0 50px 0px"
						text-align="left"
						sm-margin="0px 0 35px 0px"
						margin="16px 0 50px 0px"
						color="#60666d"
					>
						Щоб отримати додаткову інформацію про наші послуги або записатися на прийом, будь ласка, зв'яжіться з нами за телефоном <Strong>093 634 68 86</Strong> або напишіть нам на <Strong>contact@terraxspike.com</Strong>. Наша дружня команда завжди готова допомогти вам і надати кваліфіковану консультацію щодо підтримки краси та продуктивності вашого автомобіля.
						<br/><br/>
						Місцезнаходження: <Strong>Кордонна 38, Одеса</Strong>
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});